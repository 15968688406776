.sliding-panel-container.active {
  background-color: transparent;
}

.panel {
  box-shadow: 0 3px 8px 0 rgba(49, 66, 96, 0.4);
  max-height: calc(100vh - 56px);
  margin-top: 56px;
}

/** 
 * iPad with portrait orientation.
 */
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .panel {
    max-height: calc(1024px - 56px);
  }
}

/** 
 * iPad with landscape orientation.
 */
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
  .panel {
    max-height: calc(768px - 56px);
  }
}

/* iPad Pro Portrait */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .panel {
    max-height: calc(1366px - 56px);
  }
}

/* iPad Pro  Landscape */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  .panel {
    max-height: calc(1024px - 56px);
  }
}
