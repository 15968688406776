.sliding-panel-container.active {
  background-color: transparent;
}

.panel {
  box-shadow: 0 3px 8px 0 rgba(49, 66, 96, 0.4);
  max-height: calc(100vh - 56px);
  margin-top: 56px;
}

/** 
 * iPad with portrait orientation.
 */
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .panel {
    max-height: calc(1024px - 56px);
  }
}

/** 
 * iPad with landscape orientation.
 */
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
  .panel {
    max-height: calc(768px - 56px);
  }
}

/* iPad Pro Portrait */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .panel {
    max-height: calc(1366px - 56px);
  }
}

/* iPad Pro  Landscape */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  .panel {
    max-height: calc(1024px - 56px);
  }
}

[data-reach-tab-list] {
  background-color: white;
  padding: 0 16px;
  border-bottom: 4px solid #31426020;
}

[data-reach-tab] {
  position: relative;
  color: #9799aa;
  outline: none;
  border: none;
  padding: 14px 16px 14px 16px;
  cursor: pointer;
}

[data-reach-tab][data-selected] {
  color: #314260;
}

[data-reach-tab][data-selected]::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 4px;
  background-color: #314260;
}

[data-reach-tab-panels]:focus,
[data-reach-tab-panel]:focus {
  outline: none;
}

/*Dialog tabs css*/


.dialog-body [data-reach-tab-list] {
  background-color: #F2F5FB;
  padding: 0 32px;
  margin: 0 -32px;
  border-bottom: 4px solid #31426020;
}

.dialog-body [data-reach-tab] {
  background-color: transparent;
}

.dialog-body [data-reach-tab-panels] {
  padding-top: 8px;
}
