[data-reach-tab-list] {
  background-color: white;
  padding: 0 16px;
  border-bottom: 4px solid #31426020;
}

[data-reach-tab] {
  position: relative;
  color: #9799aa;
  outline: none;
  border: none;
  padding: 14px 16px 14px 16px;
  cursor: pointer;
}

[data-reach-tab][data-selected] {
  color: #314260;
}

[data-reach-tab][data-selected]::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 4px;
  background-color: #314260;
}

[data-reach-tab-panels]:focus,
[data-reach-tab-panel]:focus {
  outline: none;
}

/*Dialog tabs css*/


.dialog-body [data-reach-tab-list] {
  background-color: #F2F5FB;
  padding: 0 32px;
  margin: 0 -32px;
  border-bottom: 4px solid #31426020;
}

.dialog-body [data-reach-tab] {
  background-color: transparent;
}

.dialog-body [data-reach-tab-panels] {
  padding-top: 8px;
}